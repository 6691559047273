<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="select_head">
      <div class="select">
        <el-input placeholder="请输入手机号" v-model="phone"></el-input>
        <div class="block">
          <el-date-picker
            v-model="selectDate"
            align="right"
            type="date"
            placeholder="选择日期"
            :clearable="false"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>

        <div>
          <el-button type="primary" @click="searchList">查询</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cashTime" label="提现日期" width="200">
        </el-table-column>
        <el-table-column prop="realname" label="真实姓名" width="130">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="160">
        </el-table-column>
        <el-table-column prop="fromType" label="提现类型" width="130">
        </el-table-column>
        <el-table-column prop="cashMethod" label="提现方式" align="center">
        </el-table-column>
        <el-table-column prop="cashAccount" label="提现账号" width="200">
        </el-table-column>
        <el-table-column prop="bananceBef" label="提现前余额" width="180">
        </el-table-column>
        <el-table-column prop="received" label="提现到账金额" width="180">
        </el-table-column>
        <el-table-column prop="convertRmb" label="折合人民币" width="130">
        </el-table-column>
        <el-table-column prop="commission" label="提现手续费" width="180">
        </el-table-column>
        <el-table-column prop="bananceAft" label="提现后用户金额" width="180">
        </el-table-column>
        <el-table-column prop="sucUser" label="成单用户" width="180">
        </el-table-column>
        <el-table-column prop="cpleCurrent" label="当前CPLE" width="150">
        </el-table-column>
        <el-table-column
          prop="triptocpleAdd"
          label="累计行程获取CPLE"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="receivedAdd" label="累计提现金额" width="180">
        </el-table-column>
        <el-table-column prop="count" label="累计提现次数" width="180">
        </el-table-column>
        <el-table-column prop="status" label="提现状态"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template>
            <div class="operation">已提现</div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import "./index.scss";
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      phone: "", // input手机号
      selectDate: "", // 时间选择器
      zhifubao: "",
      tableData: [],

      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "现金提现",
        },
        {
          name: "提现成功",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.getWalletList,
      method: "POST",
      params: JSON.stringify({
        cashTime: "",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        sign: "",
        status: "1",
        token: "",
      }),
      resFunc: (res) => {
        this.tableData = res.data.data.list;
        this.pageLength = res.data.data.total;
        this.pageSize = res.data.data.pageSize;

        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }

        res.data.data.list.map((item) => {
          this.changeItem(item);
        });
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    // 查询
    searchList() {
      if (this.phone || this.selectDate) {
        showLoading();
        const opt = {
          url: reqUrl.getWalletList,
          method: "POST",
          params: JSON.stringify({
            cashTime: this.selectDate,
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            sign: "",
            status: "1",
            token: "",
          }),
          resFunc: (res) => {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            hideLoading();

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getWalletList,
        method: "POST",
        params: JSON.stringify({
          cashTime: this.selectDate,
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          sign: "",
          status: "1",
          token: "",
        }),
        resFunc: (res) => {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage = data.pageIndex;

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }

          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 重置
    reset() {
      if (this.phone || this.selectDate) {
        this.phone = "";
        this.selectDate = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 字段转换文字
    changeItem(item) {
      if (item.fromType == 1) {
        item.fromType = "余额账户";
      } else if (item.fromType == 2) {
        item.fromType = "CPLE积分账户";
      } else if (item.fromType == 3) {
        item.fromType = "支付宝账户";
      } else if (item.fromType == 4) {
        item.fromType = "微信账户";
      } else if (item.fromType == 5) {
        item.fromType = "京东账户";
      } else if (item.fromType == 6) {
        item.fromType = "转出额度";
      } else if (item.fromType == 7) {
        item.fromType = "USDT";
      } else if (item.fromType == 8) {
        item.fromType = "链上CPLE TOKEN";
      } else if (item.fromType == 9) {
        item.fromType = "数字人民币";
      } else if (item.fromType == 10) {
        item.fromType = "95%余额+5%cple";
      }

      if (item.cashMethod == 1) {
        item.cashMethod = "支付宝";
      } else if (item.cashMethod == 2) {
        item.cashMethod = "微信";
      } else if (item.cashMethod == 3) {
        item.cashMethod = "钱包地址";
      }

      if (item.status == 0) {
        item.status = "待提现";
      } else if (item.status == 1) {
        item.status = "提现成功";
      } else if (item.status == 2) {
        item.status = "提现失败";
      }

      item.cashTime = format_time_date(item.cashTime);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>
